import { render, staticRenderFns } from "./surveyPapgeDetails2.vue?vue&type=template&id=f060a78e&scoped=true&"
import script from "./surveyPapgeDetails2.vue?vue&type=script&lang=js&"
export * from "./surveyPapgeDetails2.vue?vue&type=script&lang=js&"
import style0 from "./surveyPapgeDetails2.vue?vue&type=style&index=0&id=f060a78e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f060a78e",
  null
  
)

export default component.exports