<!--添加题目-->
<template>
    <div class="main">
        <div class="main">
            <div style="font-size: 26px; font-weight: bold;display:flex">
                <div style="color:#C8C8C8;cursor: pointer;" @click="goback">&lt;调查问卷</div>
                <div>/查看统计</div>
            </div>

            <div style="display: flex;align-items:center;margin: 20px 0;">
                <div style="font-size: 20px;font-weight: bold;">问卷内容</div>
                
            </div>
            <div style="background: rgb(251, 246, 246);padding: 20px;">
                <div v-for="(item, index) in list" :key="index">
                    <div
                        style="margin: 30px 0;"
                    >{{ index + 1 }}、{{ item.title }} {{ item.type == 1 ? '(选择题)' : '(填空题)' }}<el-button
                    type="primary"
                    style="height: 27px;line-height: 5px;"
                    v-if="item.type==2"
                    @click="getList(item.id)"
                >查看详情</el-button></div>
                    <div style="display: flex;">
                        <div
                            style="margin: 0 20px;"
                            v-for="(item2, index2) in item.surveyOptionInfoList"
                            :key="index2"
                        >{{ item2.title }}({{ item2.userSelectCount ? item2.userSelectCount : 0 }}/{{ item2.userCount ? item2.userCount : 0 }})</div>
                    </div>
                </div>
            </div>
            <!-- <div style="margin: 50px 0;display: flex;"><el-button type="primary" style="margin-right: 20px;" @click="addquestionnaire">添加问卷</el-button><el-button type="primary">取消</el-button></div> -->
        </div>
        <el-dialog
            title="添加试题"
            :visible.sync="dialogVisible"
            width="50%"
            :before-close="handleClose"
        >
            <template>
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="answerTxt" label="回复内容" align="center"></el-table-column>
                    <el-table-column prop="userName" label="回复人"  align="center"></el-table-column>
                    <el-table-column prop="phoneNum" label="联系方式"  align="center"></el-table-column>
                </el-table>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { questiontypeList, levelList, qssaveOrUpdate, getCosCredit, addPaper, questionlistSta,answerlistUser} from "../../apis/index";
var COS = require('cos-js-sdk-v5');
export default {
    data() {
        return {
            list: [],
            courseId: 3,//课程id
            typeList: [
                {
                    id: 1,
                    name: '小程序'
                }, {
                    id: 2,
                    name: '课程包'
                }
            ],
            type: '',//问卷类型
            title: '',//问卷标题
            pushRangeList: [
                {
                    id: 100,
                    name: '全量'
                }, {
                    id: 170,
                    name: '70%'
                },
                {
                    id: 150,
                    name: '50%'
                },
                {
                    id: 3,
                    name: '私域'
                },
                {
                    id: 13,
                    name: '公域'
                }
            ],
            pushRange: '',//推送范围
            timeList: [],//问卷生效时间
            value1: [],
            imglist: [],
            textarea2: '',
            textarea: '',
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            textarea: "",
            checkList: [],
            radio: "1",
            options: [
                {
                    id: 1,
                    name: '选择题'
                }, {
                    id: 3,
                    name: '填空题'
                }

            ],//题目类型列表
            value: "",
            levelLists: [],//等级列表
            levelId: '',//等级值
            selectData: [
                {
                    label: "1",
                    name: "A",
                    value: "",
                },
                {
                    label: "2",
                    name: "B",
                    value: "",
                },
                {
                    label: "3",
                    name: "C",
                    value: "",
                },
            ],
             tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
        };
    },
    watch: {
        value(newdata, olddate) {
            console.log(newdata);
            if (newdata == 1) {
                this.selectData = [
                    {
                        label: "1",
                        name: "A",
                        value: "",
                    },
                    {
                        label: "2",
                        name: "B",
                        value: "",
                    },
                    {
                        label: "3",
                        name: "C",
                        value: "",
                    },
                ];
            } else if (newdata == 3) {
                this.selectData = [
                    {
                        label: "1",
                        name: "第1空",
                        value: "",
                    },
                ];
            }
            // else if (newdata == 2) {
            //     this.selectData = [
            //         {
            //             label: "1",
            //             name: "正确",
            //         },
            //         {
            //             label: "2",
            //             name: "错误",
            //         },
            //     ];
            // }
        },
        checkList() {
            this.selectData.forEach(item => {
                item.code = 0
            })
        },
        radio() {
            this.selectData.forEach(item => {
                item.code = 0
            })
        },
    },
    created() {
        // console.log(this.$route.meta.keepAlive)
        // console.log(JSON.parse(this.$route.query.items))
        let id = JSON.parse(this.$route.query.items).id
        this.questype(id)
        // this.getList()
        // this.leave()
    },

    methods: {
       async getList(id){//填空题列表
           this.dialogVisible = true
            const params={
                pageIndex:1,
                pageSize:10,
                questionId:id
            }
           const {data}=await answerlistUser(params)
           if(data.code==200){
               this.tableData=data.data.records
           }
           console.log(data)
        },
        async addquestionnaire() {//添加问卷
            const params = {
                courseId: this.courseId,
                endTime: this.timeList[1],
                picUri: this.imglist,
                pushRange: this.pushRange,
                startTime: this.timeList[0],
                title: this.title,
                type: this.type
            }
            const { data } = await addPaper(params)
            if (data.code == 200) {
                this.$message(data.msg);
                setTimeout(() => {
                    this.$router.go(-1)
                }, 100)
            } else {
                this.$message.error(data.msg);
            }
        },
        handleClose() {
            this.dialogVisible = false
        },
        deleteImg() {//删除图片
            this.imglist = ''
        },
        insterLine() {
            this.textarea = this.textarea + '____'
        },
        uploadimg(e) {//上传图片
            if (this.$refs['file'].files[0]) {
                let selectFileobj = this.$refs['file'].files[0]
                this.upload(selectFileobj)
            }
        },
        upload(selectFileobj) {
            let that = this
            let name = selectFileobj.name
            // if (that.levelId == '') {
            //     that.$message.error('请先选择等级')
            //     document.getElementById('file').value = ''
            //     return
            // }
            let filename = 'content/resource/surveyimage/' +
                name.slice(0, name.length - 4) +
                new Date().getTime() +
                name.slice(-4);
            console.log(filename)
            var cos = new COS({
                getAuthorization: function (options, callback) {
                    getCosCredit().then(result => {
                        var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                        sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
                    })
                }
            });
            cos.options.getAuthorization('', function (obj) {
                cos.putObject({
                    Bucket: sessionStorage.getItem('bucket'), /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: filename,              /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: selectFileobj, // 上传文件对象
                    onProgress: function (progressData) {
                        // console.log(JSON.stringify(progressData));
                        console.log(progressData)
                    }
                }, function (err, data) {
                    console.log(err || data);
                    if (data) {
                        let url = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
                        that.imglist = url
                        // that.imglist.push(url)
                        // console.log(that.imglist)
                    }
                });
            })

        },
        goback() {
            this.$router.go(-1)
        },
        async submit() {
            if (this.value == 1) {//选择题
                let selectData = this.selectData
                let checkList = this.checkList
                selectData.forEach(item => {
                    checkList.forEach(item2 => {
                        if (item.label == item2) {
                            item.code = 1
                        }
                    })
                })
                let str = ''
                const titles = selectData.map(item => {
                    if (item.code) {
                        str += item.code
                    } else {
                        str += 0
                    }
                    return item.name + item.value
                })
                let len = 8 - str.length
                for (var i = 0; i < len; i++) {
                    str += '0'
                }
                const title = titles.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    rightKey: str,
                    answer: title
                }
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 2) {//判断题
                let selectData = this.selectData
                // let checkList=this.radio
                selectData.forEach(item => {

                    if (item.label == this.radio) {
                        item.code = 1
                    }

                })
                let str = ''
                const titles = selectData.map(item => {
                    if (item.code) {
                        str += item.code
                    } else {
                        str += 0
                    }
                    return item.name + item.value
                })
                let len = 8 - str.length
                for (var i = 0; i < len; i++) {
                    str += '0'
                }
                const title = titles.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    rightKey: str,
                    // answer:title
                }
                // console.log(params)
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 3) {//填空题
                let selectData = this.selectData
                let checkList = this.checkList
                selectData.forEach(item => {
                    checkList.forEach(item2 => {
                        if (item.label == item2) {
                            item.code = 1
                        }
                    })
                })
                let str = ''
                const titles = selectData.map(item => {
                    if (item.code) {
                        str += item.code
                    } else {
                        str += 0
                    }
                    // return item.name+item.value
                    return item.value
                })
                let len = 8 - str.length
                for (var i = 0; i < len; i++) {
                    str += '0'
                }
                const title = titles.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    // rightKey:str,
                    answer: title,
                    answerCount: this.selectData.length
                }
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 4) {//主观题
                let selectData = this.selectData
                let checkList = this.checkList
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    answer: this.textarea2
                }
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            } else if (this.value == 5) {//看图说话
                let selectData = this.selectData
                let checkList = this.checkList
                let resourceUri = this.imglist.join('&&&')
                const params = {
                    typeId: this.value,
                    levelId: this.levelId,
                    content: this.textarea,
                    answer: this.textarea2,
                    resourceUri: resourceUri
                }
                // console.log(params)
                const { data } = await qssaveOrUpdate(params)
                if (data.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error(data.msg)
                }
            }

            //   let selectData=this.selectData
            //   let checkList=this.checkList
            //   selectData.forEach(item=>{
            //     checkList.forEach(item2=>{
            //     if(item.label==item2){
            //       item.code=1
            //     }
            //   })
            //   })
            //   let str=''
            // const titles=selectData.map(item=>{
            //     if(item.code){
            //       str+=item.code
            //     }else{
            //       str+=0
            //     }
            //     return item.name+item.value
            //   })
            //   let len=8-str.length
            //   for(var i=0;i<len;i++){
            //     str+='0'
            //   }
            //   const title=titles.join('&&&')
            //      const params={
            //         typeId:this.value,
            //         levelId:this.levelId,
            //         content:this.textarea,
            //         rightKey:str,
            //         answer:title
            //   }
            //   console.log(params)
        },

        async leave() {//获取等级列表
            const { data } = await levelList({ roleId: 3 })
            if (data.code == 200) {
                this.levelLists = data.data
            } else {
                this.$message.error(data.msg)
            }
        },
        async questype(id) {//题目类型列表
            const { data } = await questionlistSta({ surveyId: id })
            console.log(data.data)
            if (data.code == 200) {
                this.list = data.data
            }
            // let qslist = data.data
            // this.options = qslist;

        },
        handChange(file, fileList) {
            this.fileList = fileList
        },
        handleRemove(file) {
            let uid = file.uid
            let fileList = this.fileList
            fileList.map((item, index) => {
                if (item.uid == uid) {
                    this.fileList.splice(index, 1)
                }
            })
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        add() {
            if (!this.value) {
                this.$message.error('请先选择题目类型')
                return
            }
            //添加
            if (this.value == 1) {
                let selectData = this.selectData;
                let res = selectData.some((item) => {
                    return item.name == "D";
                });
                if (res) {
                    let res = selectData.some((item) => {
                        return item.name == "E";
                    });
                    if (res) {
                        let res = selectData.some((item) => {
                            return item.name == "E";
                        });
                        if (res) {
                            let res = selectData.some((item) => {
                                return item.name == "F";
                            });
                            if (!res) {
                                this.selectData.push({
                                    label: "6",
                                    name: "F",
                                    value: "",
                                });
                            }
                        }
                    } else {
                        this.selectData.push({
                            label: "5",
                            name: "E",
                            value: "",
                        });

                        console.log(this.selectData);
                    }
                } else {
                    this.selectData.push({
                        label: "4",
                        name: "D",
                        value: "",
                    });
                    console.log(this.selectData);
                }
            } else if (this.value == 3) {
                let selectData = this.selectData;
                selectData.push({
                    label: selectData.length + 1,
                    name: `第${selectData.length + 1}空`,
                    value: "",
                })
                this.selectData = selectData
                console.log(selectData)
            }
            // else if (this.value == 3) {
            //   let selectData = this.selectData;
            //   let res = selectData.some((item) => {
            //     return item.name == "第二空";
            //   });
            //   if (res) {
            //     let res = selectData.some((item) => {
            //       return item.name == "第三空";
            //     });
            //     if (res) {
            //       let res = selectData.some((item) => {
            //         return item.name == "第四空";
            //       });
            //       if (res) {
            //         let res = selectData.some((item) => {
            //           return item.name == "第五空";
            //         });
            //         if (res) {
            //           let res = selectData.some((item) => {
            //             return item.name == "第五空";
            //           });
            //           if (res) {
            //             this.selectData.push({
            //               label: "6",
            //               name: "第六空",
            //               value: "",
            //             });
            //           }
            //         } else {
            //           this.selectData.push({
            //             label: "5",
            //             name: "第五空",
            //             value: "",
            //           });
            //         }
            //       } else {
            //         this.selectData.push({
            //           label: "4",
            //           name: "第四空",
            //           value: "",
            //         });
            //       }
            //     } else {
            //       this.selectData.push({
            //         label: "3",
            //         name: "第三空",
            //         value: "",
            //       });

            //       console.log(this.selectData);
            //     }
            //   } else {
            //     this.selectData.push({
            //       label: "2",
            //       name: "第二空",
            //       value: "",
            //     });
            //     console.log(this.selectData);
            //   }
            // }

        },
        dele(ind) {
            this.selectData.splice(ind, 1);
        },
    },
};
</script>

<style lang='less' scoped>
.main {
    background: #fff;
    margin: 19px 0;
    padding: 23px;
    border-radius: 14px;
}
.title {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    div {
        display: flex;
    }
    .line {
        width: 9px;
        height: 22px;
        border-radius: 6px;
        background: #409eff;
        margin-right: 11px;
    }
}
.title > div {
    width: 50%;
    margin: 29px 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    //   align-items: center;
}
.titleleft {
    display: flex;
    flex-direction: column;
}
/deep/.el-form-item__label {
    width: 100px;
}
/deep/.el-radio__label,
/deep/.el-checkbox__label {
    display: flex;
    align-items: center;
}
.ft {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
</style>
